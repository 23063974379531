import { getHeaderText, sortAlphabetically } from "../lib/helpers";

const getTopThemes = (media, limit) => {
  const tallyReducer = (acc, m) => {
    m.themes.forEach((theme) => {
      if (acc[theme.id]) {
        acc[theme.id].tally += 1;
      } else {
        acc[theme.id] = { ...theme, tally: 1 };
      }
    });
    return acc;
  };

  return Object.values(media.reduce(tallyReducer, {}))
    .sort((a, b) => b.tally - a.tally)
    .slice(0, limit)
    .map((talliedTheme) => {
      const { tally, ...withoutTally } = talliedTheme;
      return withoutTally;
    })
    .sort(sortAlphabetically);
};

const getUniqueCategories = (media) => {
  const categoryReducer = (acc, m) => {
    if (!m.category || acc[m.category.id]) {
      return acc;
    }

    acc[m.category.id] = m.category;
    return acc;
  };
  const categoryMap = media.reduce(categoryReducer, {});
  return Object.values(categoryMap)
    .map((category) => category)
    .sort(sortAlphabetically);
};

const getAlbumSources = (media) => {
  const albumSourceReducer = (acc, m) => {
    const source = getHeaderText(m.title)[1];

    if (!source || acc[source]) {
      return acc;
    }

    acc[source] = { id: source, title: source };

    return acc;
  };

  const sourceMap = media.reduce(albumSourceReducer, {});
  return Object.values(sourceMap)
    .map((source) => source)
    .sort(sortAlphabetically);
};

function useAlbumMetadata({ media }, themesLimit) {
  const themes = getTopThemes(media, themesLimit);
  const categories = getUniqueCategories(media, themesLimit);
  const sources = getAlbumSources(media, themesLimit);

  return { themes, categories, sources };
}

export default useAlbumMetadata;
