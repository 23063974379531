import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";
import { Link as GatsbyLink } from "gatsby";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import { format } from "date-fns";
import { albumType } from "../../types";
import MediaImagePlaceholder from "../MediaImagePlaceholder";
import InlineList from "../InlineList";
import NoContent from "../NoContent";
import useAlbumMetadata from "../../hooks/useAlbumMetadata";
import ThemeLink from "../ThemeLink";
import CategoryLink from "../CategoryLink";
import SourceLink from "../SourceLink";

const useStyles = makeStyles((theme) => ({
  itemContent: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "flex-start",
  },
  itemBody: {
    flex: 1,
  },
  divider: {
    alignSelf: "stretch",
  },
  section: {
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
  },
  sectionHeading: {
    marginRight: theme.spacing(1),
  },
}));

function AlbumListItemSection({ title, items }) {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <Typography
        variant="overline"
        component="h6"
        display="inline"
        className={classes.sectionHeading}
        color="textSecondary"
      >
        {title}{" "}
      </Typography>
      <InlineList items={items} />
    </section>
  );
}

function AlbumListItem({ album, style }) {
  if (!album) {
    return null;
  }

  const classes = useStyles();
  const { title, publishedAt } = album;
  const to = `/album/${album.slug.current}`;
  const { themes: topThemes, categories, sources } = useAlbumMetadata(
    album,
    25
  );

  const sourceLinks = sources.map((source) => <SourceLink source={source} />);
  const themeLinks = topThemes.map((theme) => <ThemeLink theme={theme} />);
  const categoryLinks = categories.map((category) => (
    <CategoryLink category={category} />
  ));

  return (
    <ListItem disableGutters style={style}>
      <Grid container spacing={3} alignItems="center">
        <Grid item sm={4} xs={12}>
          <Link
            color="textPrimary"
            component={GatsbyLink}
            to={to}
            title={`View ${album.title}`}
          >
            <MediaImagePlaceholder type="album" height={200} />
          </Link>
        </Grid>
        <Grid item sm={8} xs={12} className={classes.itemContent}>
          <Typography
            variant="h6"
            component="h4"
            gutterBottom
            className={classes.heading}
          >
            <Link component={GatsbyLink} to={to}>
              {title}
            </Link>
            <Typography color="textSecondary" component="span" variant="body2">
              {format(publishedAt, "MMMM Do, YYYY")}
            </Typography>
          </Typography>
          <div className={classes.itemBody}>
            {sources.length > 0 && (
              <AlbumListItemSection title="Sources" items={sourceLinks} />
            )}
            {topThemes.length > 0 && (
              <AlbumListItemSection title="Top Themes" items={themeLinks} />
            )}
            {categories.length > 0 && (
              <AlbumListItemSection title="Categories" items={categoryLinks} />
            )}
          </div>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    </ListItem>
  );
}

function AlbumList({ albums }) {
  return (
    <List>
      {albums.length > 0 ? (
        albums.map((album, index) => (
          <Fade
            key={album.id}
            in
            style={{ transitionDelay: `${index * 50}ms` }}
          >
            <AlbumListItem album={album} />
          </Fade>
        ))
      ) : (
        <NoContent text="No Albums to Show" />
      )}
    </List>
  );
}

AlbumListItemSection.defaultProps = {
  items: [],
  title: "",
};

AlbumListItemSection.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};
AlbumListItem.defaultProps = {
  album: null,
  style: null,
};

AlbumListItem.propTypes = {
  album: albumType,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

AlbumList.defaultProps = {
  albums: [],
};

AlbumList.propTypes = {
  albums: PropTypes.arrayOf(albumType),
};

export default AlbumList;
