import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { childrenType } from "../../types";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));

function DetailSection({ title, children }) {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Typography variant="overline" component="h3" color="textSecondary">
        {title}
      </Typography>
      {children}
    </section>
  );
}

DetailSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: childrenType.isRequired,
};

export default DetailSection;
