import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { albumType, errorType } from "../types";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import GraphQLErrorList from "../components/GraphQLErrorList";
import { mapEdgesToNodes } from "../lib/helpers";
import AlbumDetail from "../components/AlbumDetail";

export const query = graphql`
  query AlbumTemplateQuery($id: String!) {
    album: sanityAlbum(id: { eq: $id }) {
      ...Album
    }
    recentAlbums: allSanityAlbum(
      sort: { fields: [publishedAt], order: DESC }
      limit: 10
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      ...AlbumEdge
    }
  }
`;

const AlbumTemplate = ({ data, errors }) => {
  const album = data && data.album;
  const recentAlbums =
    data && data.recentAlbums && mapEdgesToNodes(data.recentAlbums);
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {album && (
        <SEO
          title={album.title || "Untitled"}
          description={album.description || "No description"}
        />
      )}
      {errors && <GraphQLErrorList errors={errors} />}
      {album && <AlbumDetail album={album} recentAlbums={recentAlbums} />}
    </Layout>
  );
};

AlbumTemplate.defaultProps = {
  errors: null,
};

AlbumTemplate.propTypes = {
  data: PropTypes.shape({
    album: albumType,
    recentAlbums: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({ node: albumType })),
    }),
  }).isRequired,
  errors: PropTypes.arrayOf(errorType),
};

export default AlbumTemplate;
