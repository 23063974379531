import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import { albumType } from "../../types";
import Section from "../Section";
import AlbumList from "../AlbumList";
import AlbumMedia from "../AlbumMedia";
import DetailSection from "../DetailSection";
import useAlbumMetadata from "../../hooks/useAlbumMetadata";
import InlineList from "../InlineList";
import ThemeLink from "../ThemeLink";
import CategoryLink from "../CategoryLink";
import SourceLink from "../SourceLink";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
  media: {
    marginBottom: theme.spacing(4),
  },
  metadata: {
    padding: theme.spacing(2, 4),
  },
}));

function AlbumDetail({ album, recentAlbums }) {
  const { title, publishedAt } = album;
  const classes = useStyles();

  const { themes, categories, sources } = useAlbumMetadata(album);

  const sourceLinks = sources.map((source) => <SourceLink source={source} />);
  const themeLinks = themes.map((theme) => <ThemeLink theme={theme} />);
  const categoryLinks = categories.map((category) => (
    <CategoryLink category={category} />
  ));

  return (
    <div className={classes.root}>
      <Section heading={title} background="dark" isArticle>
        <AlbumMedia album={album} className={classes.media} />
        <Paper className={classes.metadata}>
          <DetailSection title="Sources">
            <InlineList items={sourceLinks} />
          </DetailSection>
          <DetailSection title="Themes">
            <InlineList items={themeLinks} />
          </DetailSection>
          <DetailSection title="Categories">
            <InlineList items={categoryLinks} />
          </DetailSection>
          <DetailSection title="Published">
            <Typography variant="body1">
              {format(publishedAt, "MMMM Do, YYYY")}
            </Typography>
          </DetailSection>
        </Paper>
      </Section>
      <Section heading="Recent Albums">
        <AlbumList albums={recentAlbums} />
      </Section>
    </div>
  );
}

AlbumDetail.defaultProps = {
  album: null,
  recentAlbums: [],
};

AlbumDetail.propTypes = {
  album: albumType,
  recentAlbums: PropTypes.arrayOf(albumType),
};

export default AlbumDetail;
