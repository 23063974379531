import React from "react";
import Link from "@material-ui/core/Link";
import { Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";

const SourceLink = ({ source }) => {
  return (
    <Link
      component={GatsbyLink}
      to="/media#mediaSearch"
      state={{ filter: { title: source.title } }}
      variant="body1"
    >
      {source.title}
    </Link>
  );
};

SourceLink.defaultProps = {
  source: null,
};

SourceLink.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default SourceLink;
